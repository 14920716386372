import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import { WithContext as ReactTags } from "react-tag-input";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function SiteForm() {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    name: "",
    domain: "",
    active: false,
  };
  const [state, setState] = useState(initialState);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);
  const [isChanged, setIsChanged] = useState(false);
  const isDisabled = state.name.length === 0 || !isChanged;

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const createClicked = () => {
    API.createItem("sites", state, token)
      .then((resp) => {
        history.push("/sites/");
      })
      .catch((error) => console.log(error));
  };

  const updateClicked = () => {
    if (location.state && isChanged) {
      API.updateItem("sites", location.state.id, state, token)
        .then((resp) => {
          history.push("/sites/");
        })
        .catch((error) => console.log(error));
    }
  };

  const onCancel = () => {
    // if (location.state) {
    //   if (location.state.jobid) {
    //     history.push({
    //       pathname: "/jobs/detail/",
    //       state: { id: location.state.jobid },
    //     });
    //   } else {
    //     history.push("/sites/");
    //   }
    // } else {
    //   history.push("/sites/");
    // }
    history.push("/sites/");
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        setIsChanged(false);
        API.getItem("sites", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              name: resp.name,
              domain: resp.domain,
              active: resp.active,
            });
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Site</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Site</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id
                  ? "Update Site"
                  : "New Site"}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      id="name"
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Enter name"
                      value={state.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Domain</label>
                    <input
                      id="domain"
                      name="domain"
                      className="form-control"
                      type="text"
                      placeholder="Enter domain"
                      value={state.domain}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {location.state && location.state.id ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
