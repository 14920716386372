import React, { useState } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function TTSViettel() {
  const MAXLENGTH = 10000;
  const initialState = {
    text: "",
    voice: {
      label: "Phương Trang - Nữ miền Bắc - CLC",
      value: "hn-phuongtrang",
    },
    id: "3",
    without_filter: true,
    speed: { value: 1.0, label: "1.0" },
    tts_return_option: { value: 3, label: "mp3" },
  };
  const [state, setState] = useState(initialState);
  const [count, setCount] = useState(0);

  const voiceList = [
    { label: "Quỳnh Anh - Nữ miền Bắc - CLC", value: "hn-quynhanh" },
    { label: "Diễm My - Nữ miền Nam - CLC", value: "hcm-diemmy" },
    { label: "Mai Ngọc - Nữ miền Trung - CLC", value: "hue-maingoc" },
    { label: "Phương Trang - Nữ miền Bắc - CLC", value: "hn-phuongtrang" },
    { label: "Thanh Tùng - Nam miền Bắc", value: "hn-thanhtung" },
    { label: "Bảo Quốc - Nam miền Trung", value: "hue-baoquoc" },
    { label: "Minh Quân - Nam miền Nam", value: "hcm-minhquan" },
    { label: "Việt Trinh - Nữ miền Bắc", value: "trinhthiviettrinh" },
    { label: "Lê Yến - Nữ miền Nam", value: "lethiyen" },
    { label: "Thùy Duyên - Nữ miền Nam", value: "nguyenthithuyduyen" },
    { label: "Tiến Quân - Nam miền Bắc", value: "phamtienquan" },
  ];
  const speedList = [
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
    { value: 1.1, label: "1.1" },
    { value: 1.2, label: "1.2" },
    { value: 1.3, label: "1.3" },
  ];
  const extensionList = [
    { value: 2, label: "wav" },
    { value: 3, label: "mp3" },
  ];

  const downloadFile = () => {
    var body = {
      text: state.text,
      voice: state.voice.value,
      id: "3",
      without_filter: false,
      speed: state.speed.value,
      tts_return_option: state.tts_return_option.value,
    };
    console.log("Input: ", body);
    fetch("https://viettelgroup.ai/voice/api/tts/v1/rest/syn/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: "",
      },
      body: JSON.stringify(body),
    }).then((resp) => {
      console.log("Result: ", resp);
      resp.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "sound.mp3";
        a.click();
        toast("Downloaded file!");
      });
      //window.location.href = response.url;
    });
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setCount(evt.target.value.length);
  };

  const onVoiceChange = (newValue, actionMeta) => {
    setState({ ...state, voice: newValue });
    console.groupEnd();
  };

  const onSpeedChange = (newValue, actionMeta) => {
    setState({ ...state, speed: newValue });
    console.groupEnd();
  };

  const onExtensionChange = (newValue, actionMeta) => {
    setState({ ...state, tts_return_option: newValue });
    console.groupEnd();
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Text To Speech - Viettel</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Text To Speech</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Download audio file</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Content</label>
                    <textarea
                      className="form-control"
                      id="text"
                      name="text"
                      cols="100"
                      rows="15"
                      maxLength={MAXLENGTH}
                      defaultValue={state.text}
                      placeholder="Enter text"
                      onChange={handleChange}
                    ></textarea>
                    <p>{count}{count > 1 ? " characters" : " character"}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Voice</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.voice}
                      name="voice"
                      options={voiceList}
                      onChange={onVoiceChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Speed</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.speed}
                      name="speed"
                      options={speedList}
                      onChange={onSpeedChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Extension</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.tts_return_option}
                      name="tts_return_option"
                      options={extensionList}
                      onChange={onExtensionChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="cell-button btn btn-success"
                onClick={downloadFile}
              >
                Download
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
