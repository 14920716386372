import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import useFetch from "../../hooks/useFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import { commonFunction } from "../common/common-functions";
import { dataCommonFunction } from "../common/data-common-function";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function CategoryForm() {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    name: "",
    slug: "",
    parent: "",
    site: "",
    active: false,
  };
  const [state, setState] = useState(initialState);

  // Sites
  const sites_db = useFetch(`${process.env.REACT_APP_API_URL}/sites/`, "GET");
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);
  const [isChanged, setIsChanged] = useState(false);
  const isDisabled =
    state.name.length === 0 || state.slug.length === 0 || !isChanged;

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const handleSlugChange = (evt) => {
    const slug = commonFunction.slugify(evt.target.value);
    setState({
      ...state,
      name: evt.target.value,
      slug: slug,
    });
    setIsChanged(true);
  };

  const onSiteChange = (newValue, actionMeta) => {
    setSelectedSite(newValue);
    setState({
      ...state,
      site: newValue.value,
    });
    var link = "get_categories_by_site_id/" + newValue.value.toString() + "/";
    API.getItems(link, token)
      .then((resp) => {
        if (resp.categories && resp.categories.length > 0) {
          const category_options = dataCommonFunction.createSelectOption(
            resp.categories
          );
          setCategories(category_options);
        } else {
          setCategories([]);
        }
      })
      .catch((error) => console.log(error));
    setIsChanged(true);
    console.groupEnd();
  };

  const onCategoryChange = (newValue, actionMeta) => {
    setSelectedCategory(newValue);
    setState({
      ...state,
      parent: newValue.value,
    });
    setIsChanged(true);
    console.groupEnd();
  };

  const createClicked = () => {
    API.createItem("categories", state, token)
      .then((resp) => {
        history.push("/categories/");
      })
      .catch((error) => console.log(error));
  };

  const updateClicked = () => {
    if (location.state && isChanged) {
      API.updateItem("categories", location.state.id, state, token)
        .then((resp) => {
          history.push("/categories/");
        })
        .catch((error) => console.log(error));
    }
  };

  const onCancel = () => {
    // if (location.state) {
    //   if (location.state.jobid) {
    //     history.push({
    //       pathname: "/jobs/detail/",
    //       state: { id: location.state.jobid },
    //     });
    //   } else {
    //     history.push("/sites/");
    //   }
    // } else {
    //   history.push("/sites/");
    // }
    history.push("/categories/");
  };

  useEffect(() => {
    // Sites
    const site_options = dataCommonFunction.createSelectOption(
      sites_db.response
    );
    setSites(site_options);

    if (location.state) {
      if (location.state.id) {
        var category_id = location.state.id;
        setIsChanged(false);
        API.getItem("categories", category_id, token)
          .then((resp) => {
            setState({
              ...state,
              name: resp.name,
              slug: resp.slug,
              parent: resp.parent,
              site: resp.site,
              active: resp.active,
            });

            setSelectedSite(
              site_options.find((element) => {
                return element.value === resp.site;
              })
            );

            var link =
              "get_categories_by_site_id_except_children/" +
              resp.site.toString() +
              "/" +
              category_id.toString() +
              "/";
            API.getItems(link, token)
              .then((resp1) => {
                if (resp1.categories && resp1.categories.length > 0) {
                  const category_options =
                    dataCommonFunction.createSelectOption(resp1.categories);
                  setCategories(category_options);
                  if (resp.parent) {
                    setSelectedCategory(
                      category_options.find((element) => {
                        return element.value === resp.parent;
                      })
                    );
                  }
                }
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, [sites_db.response]);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Category</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Category</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id
                  ? "Update Category"
                  : "New Category"}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Name</label>
                    {location.state && location.state.id ? (
                      <input
                        id="name"
                        name="name"
                        className="form-control"
                        type="text"
                        placeholder="Enter name"
                        value={state.name}
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        id="name"
                        name="name"
                        className="form-control"
                        type="text"
                        placeholder="Enter name"
                        value={state.name}
                        onChange={handleSlugChange}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Site</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={selectedSite}
                      name="site"
                      options={sites}
                      onChange={onSiteChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Slug</label>
                    <input
                      id="slug"
                      name="slug"
                      className="form-control"
                      type="text"
                      placeholder="Enter slug"
                      value={state.slug}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Parent</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={selectedCategory}
                      name="parent"
                      options={categories}
                      onChange={onCategoryChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {location.state && location.state.id ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
