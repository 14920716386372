import React, { useState } from "react";
import Select from "react-select";
import { API } from "../common/api-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function TTSGoogleUnlogin() {
  const MAXLENGTH = 3700;
  const initialState = {
    text: "",
    voice: {
      label: "vi-VN-Standard-C",
      value: "vi-VN-Standard-C",
    },
    id: "3",
    without_filter: true,
    speaking_rate: { value: 1.0, label: "1.0" },
    pitch: { value: 1.0, label: "1.0" },
    tts_return_option: { value: 3, label: "mp3" },
    text1: "",
    text2: "",
  };
  const [state, setState] = useState(initialState);
  const [count, setCount] = useState(0);

  const voiceList = [
    { label: "vi-VN-Neural2-A", value: "vi-VN-Neural2-A" },
    { label: "vi-VN-Neural2-D", value: "vi-VN-Neural2-D" },
    { label: "vi-VN-Standard-A", value: "vi-VN-Standard-A" },
    { label: "vi-VN-Standard-B", value: "vi-VN-Standard-B" },
    { label: "vi-VN-Standard-C", value: "vi-VN-Standard-C" },
    { label: "vi-VN-Standard-D", value: "vi-VN-Standard-D" },
    { label: "vi-VN-Wavenet-A", value: "vi-VN-Wavenet-A" },
    { label: "vi-VN-Wavenet-B", value: "vi-VN-Wavenet-B" },
    { label: "vi-VN-Wavenet-C", value: "vi-VN-Wavenet-C" },
    { label: "vi-VN-Wavenet-D", value: "vi-VN-Wavenet-D" },
  ];
  const speedList = [
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
    { value: 1.1, label: "1.1" },
    { value: 1.2, label: "1.2" },
    { value: 1.3, label: "1.3" },
  ];
  const pitch = [
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
    { value: 1.1, label: "1.1" },
    { value: 1.2, label: "1.2" },
    { value: 1.3, label: "1.3" },
  ];
  const extensionList = [
    { value: 2, label: "wav" },
    { value: 3, label: "mp3" },
  ];

  const downloadFile = () => {
    var params = {
      //   file_name: state.text1 + "-" + state.text2 + ".mp3",
      content: "<speak>" + state.text + "</speak>",
      voice: state.voice.label,
      speaking_rate: state.speaking_rate.value,
      pitch: state.pitch.value,
    };
    if (state.text2 != "") {
      params["file_name"] = state.text1 + "-" + state.text2 + ".mp3";
    } else {
      params["file_name"] = state.text1 + ".mp3";
    }
    // API.createItemFront("create_audio_file2", params)
    API.createItemFront("synthesize_ssml", params)
      .then((resp) => {
        console.log("Audio file is created!");
        toast("Audio file is created!");
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setCount(evt.target.value.length);
  };

  const onVoiceChange = (newValue, actionMeta) => {
    setState({ ...state, voice: newValue });
    console.groupEnd();
  };

  const onSpeedChange = (newValue, actionMeta) => {
    setState({ ...state, speaking_rate: newValue });
    console.groupEnd();
  };

  const onExtensionChange = (newValue, actionMeta) => {
    setState({ ...state, tts_return_option: newValue });
    console.groupEnd();
  };

  return (
    <React.Fragment>
      <section className="content">
        <div className="card card-default">
          <div className="card-header">
            <h3 className="card-title" style={{ width: "50%", float: "left" }}>
              Text To Speech - Google
            </h3>
            <div style={{ width: "50%", float: "right", textAlign: "right" }}>
              <Link to={"/"} className="nav-link">
                Home
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Content</label>
                  <textarea
                    className="form-control"
                    id="text"
                    name="text"
                    cols="100"
                    rows="20"
                    maxLength={MAXLENGTH}
                    defaultValue={state.text}
                    placeholder="Enter text"
                    onChange={handleChange}
                  ></textarea>
                  <p>
                    {count}
                    {count > 1 ? " characters" : " character"}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Text 1</label>
                  <input
                    id="text1"
                    name="text1"
                    className="form-control"
                    type="text"
                    placeholder="Enter text"
                    value={state.text1}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Text 2</label>
                  <input
                    id="text2"
                    name="text2"
                    className="form-control"
                    type="text"
                    placeholder="Enter text"
                    value={state.text2}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Voice</label>
                  <Select
                    className="basic-single selectbox-container"
                    classNamePrefix="select"
                    value={state.voice}
                    name="voice"
                    options={voiceList}
                    onChange={onVoiceChange}
                  />
                </div>
                <div className="form-group">
                  <label>Speaking rate</label>
                  <Select
                    className="basic-single selectbox-container"
                    classNamePrefix="select"
                    value={state.speaking_rate}
                    name="speaking_rate"
                    options={speedList}
                    onChange={onSpeedChange}
                  />
                </div>
                <div className="form-group">
                  <label>Pitch</label>
                  <Select
                    className="basic-single selectbox-container"
                    classNamePrefix="select"
                    value={state.pitch}
                    name="pitch"
                    options={speedList}
                    onChange={onSpeedChange}
                  />
                </div>
                <div className="form-group">
                  <label>Extension</label>
                  <Select
                    className="basic-single selectbox-container"
                    classNamePrefix="select"
                    value={state.tts_return_option}
                    name="tts_return_option"
                    options={extensionList}
                    onChange={onExtensionChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              className="cell-button btn btn-success"
              onClick={downloadFile}
            >
              Download
            </button>
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
}
