import React, { useState, useContext } from "react";
import { API } from "../common/api-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function AddExistChapter(props) {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    parents_article: location.state.parents_article,
    children_article: "",
    ordering: 0,
  };
  const [state, setState] = useState(initialState);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);
  const isDisabled =
    state.children_article.length === 0 || state.ordering.length === 0;

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const createClicked = () => {
    API.createItem("chapters", state, token)
      .then((resp) => {
        history.push({
          pathname: "/stories/detail/",
          state: { id: location.state.parents_article },
        });
      })
      .catch((error) => console.log(error));
  };

  const onCancel = () => {
    history.push({
      pathname: "/stories/detail/",
      state: { id: location.state.parents_article },
    });
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Add Exist Chapter</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Chapter</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id
                  ? "Update Chapter"
                  : "New Chapter"}
              </h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ChapterID</label>
                    <input
                      id="children_article"
                      name="children_article"
                      className="form-control"
                      type="number"
                      placeholder="Enter children article"
                      value={state.children_article}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Ordering</label>
                    <input
                      id="ordering"
                      name="ordering"
                      className="form-control"
                      type="number"
                      placeholder="Enter ordering"
                      value={state.ordering}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-info"
                onClick={createClicked}
                disabled={isDisabled}
              >
                Create
              </button>
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
