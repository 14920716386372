import React, { useState, useContext } from "react";
import Select from "react-select";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SortableHandle,
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

export default function TTSGoogle() {
  const MAXLENGTH = 3700;
  //   const PATH = "/VOLUMES/DATA/Audios/";
  const initialState = {
    text: "",
    gender: {
      label: "FEMALE",
      value: 1,
    },
    language_code: {
      label: "vi-VN",
      value: "vi-VN",
    },
    voice: {
      label: "vi-VN-Standard-C",
      value: "vi-VN-Standard-C",
    },
    id: "3",
    without_filter: true,
    speaking_rate: { value: 1.0, label: "1.0" },
    pitch: { value: 1.0, label: "1.0" },
    tts_return_option: { value: 3, label: "mp3" },
    text1: "",
    text2: "",
  };
  const [state, setState] = useState(initialState);
  const [count, setCount] = useState(0);
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const [log, setLog] = useState(0);
  const [files, setFiles] = useState([]);

  const genderList = [
    { label: "MALE", value: 0 },
    { label: "FEMALE", value: 1 },
  ];
  const languageCodeList = [
    { label: "vi-VN", value: "vi-VN" },
    { label: "en-US", value: "en-US" },
    { label: "ja-JP", value: "ja-JP" },
  ];
  const vnVoiceList = [
    { label: "vi-VN-Neural2-A", value: "vi-VN-Neural2-A" },
    { label: "vi-VN-Neural2-D", value: "vi-VN-Neural2-D" },
    { label: "vi-VN-Standard-A", value: "vi-VN-Standard-A" },
    { label: "vi-VN-Standard-B", value: "vi-VN-Standard-B" },
    { label: "vi-VN-Standard-C", value: "vi-VN-Standard-C" },
    { label: "vi-VN-Standard-D", value: "vi-VN-Standard-D" },
    { label: "vi-VN-Wavenet-A", value: "vi-VN-Wavenet-A" },
    { label: "vi-VN-Wavenet-B", value: "vi-VN-Wavenet-B" },
    { label: "vi-VN-Wavenet-C", value: "vi-VN-Wavenet-C" },
    { label: "vi-VN-Wavenet-D", value: "vi-VN-Wavenet-D" },
  ];
  const usVoiceList = [
    { label: "en-US-Standard-A", value: "en-US-Standard-A" },
    { label: "en-US-Standard-B", value: "en-US-Standard-B" },
    { label: "en-US-Standard-C", value: "en-US-Standard-C" },
    { label: "en-US-Standard-D", value: "en-US-Standard-D" },
    { label: "en-US-Standard-E", value: "en-US-Standard-E" },
    { label: "en-US-Standard-F", value: "en-US-Standard-F" },
    { label: "en-US-Standard-G", value: "en-US-Standard-G" },
    { label: "en-US-Standard-H", value: "en-US-Standard-H" },
    { label: "en-US-Standard-I", value: "en-US-Standard-I" },
    { label: "en-US-Standard-J", value: "en-US-Standard-J" },
  ];
  const jpVoiceList = [
    { label: "ja-JP-Standard-A", value: "ja-JP-Standard-A" },
    { label: "ja-JP-Standard-B", value: "ja-JP-Standard-B" },
    { label: "ja-JP-Standard-C", value: "ja-JP-Standard-C" },
    { label: "ja-JP-Standard-D", value: "ja-JP-Standard-D" },
  ];
  const [voiceList, setVoiceList] = useState(vnVoiceList);
  const speedList = [
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
    { value: 1.1, label: "1.1" },
    { value: 1.2, label: "1.2" },
    { value: 1.3, label: "1.3" },
  ];
  const pitch = [
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1.0, label: "1.0" },
    { value: 1.1, label: "1.1" },
    { value: 1.2, label: "1.2" },
    { value: 1.3, label: "1.3" },
  ];
  const extensionList = [
    { value: 2, label: "wav" },
    { value: 3, label: "mp3" },
  ];

  const DragHandle = SortableHandle(() => (
    <div className="react-sortable-hoc-control"></div>
  ));

  const SortableItem = SortableElement(({ value }) => (
    <div className="react-sortable-hoc-item">
      <DragHandle />
      {/* <div className="list__card-right"> */}
      <div style={{ width: "100%", marginBottom: 5 }}>
        <div className="list__card-right--name">{value}</div>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="react-sortable-hoc-container">
        {items
          .sort((a, b) => a.position - b.position)
          .map((value, index) => (
            <SortableItem value={value} key={`item-${value}`} index={index} />
          ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let files_arr = arrayMove(files, oldIndex, newIndex);
    setFiles(files_arr);
    console.log("files_arr", files_arr);
  };

  const concatenateFiles = () => {
    var params = {};
    params["files"] = files;
    if (state.text1 != "") {
      if (state.text2 != "") {
        params["file_name"] = state.text1 + "-" + state.text2 + "-final.mp3";
      } else {
        params["file_name"] = state.text1 + "-final.mp3";
      }
    }
    API.createItem("concatenate_files", params, token)
      .then((resp) => {
        console.log(resp);
        toast("Concatenated files!");
      })
      .catch((error) => console.log(error));
  };

  const downloadFile = () => {
    console.log("state.language_code", state.language_code);
    var params = {
      //   file_name: state.text1 + "-" + state.text2 + ".mp3",
      content: "<speak>" + state.text + "</speak>",
      gender: state.gender.value,
      language_code: state.language_code.label,
      voice: state.voice.label,
      speaking_rate: state.speaking_rate.value,
      pitch: state.pitch.value,
    };
    if (state.text2 != "") {
      params["file_name"] =
        state.text1 + "-" + state.text2 + "-" + log + ".mp3";
    } else {
      params["file_name"] = state.text1 + "-" + log + ".mp3";
    }
    console.log("params", params);
    // API.createItemFront("create_audio_file2", params)
    API.createItemFront("synthesize_ssml", params)
      .then((resp) => {
        console.log("Audio file is created!");
        toast("Audio file is created!");
      })
      .catch((error) => console.log(error));
    setLog(log + 1);
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setCount(evt.target.value.length);
  };

  const onGenderChange = (newValue, actionMeta) => {
    setState({ ...state, gender: newValue });
    console.groupEnd();
  };

  const onLanguageCodeChange = (newValue, actionMeta) => {
    console.log("newValue", newValue);
    let mystate = state;
    switch (newValue.value) {
      case "vi-VN":
        setVoiceList(vnVoiceList);
        mystate["voice"] = vnVoiceList[0];
        break;
      case "en-US":
        setVoiceList(usVoiceList);
        mystate["voice"] = usVoiceList[0];
        break;
      case "ja-JP":
        setVoiceList(jpVoiceList);
        mystate["voice"] = jpVoiceList[0];
        break;
      default:
        setVoiceList(vnVoiceList);
        mystate["voice"] = vnVoiceList[0];
    };
    mystate["language_code"] = newValue
    setState(mystate);
    console.groupEnd();
  };

  const onVoiceChange = (newValue, actionMeta) => {
    setState({ ...state, voice: newValue });
    console.groupEnd();
  };

  const onSpeedChange = (newValue, actionMeta) => {
    setState({ ...state, speaking_rate: newValue });
    console.groupEnd();
  };

  const onExtensionChange = (newValue, actionMeta) => {
    setState({ ...state, tts_return_option: newValue });
    console.groupEnd();
  };

  const listFilesInDirectory = () => {
    var link = "list_files_in_directory/";
    API.getItems(link, token)
      .then((resp) => {
        console.log(resp);
        setFiles(resp);
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Text To Speech - Google</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Text To Speech</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Download audio file</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Content</label>
                    <textarea
                      className="form-control"
                      id="text"
                      name="text"
                      cols="100"
                      rows="28"
                      maxLength={MAXLENGTH}
                      defaultValue={state.text}
                      placeholder="Enter text"
                      onChange={handleChange}
                    ></textarea>
                    <p>
                      {count}
                      {count > 1 ? " characters" : " character"}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Text 1</label>
                    <input
                      id="text1"
                      name="text1"
                      className="form-control"
                      type="text"
                      placeholder="Enter text"
                      value={state.text1}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Text 2</label>
                    <input
                      id="text2"
                      name="text2"
                      className="form-control"
                      type="text"
                      placeholder="Enter text"
                      value={state.text2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Language code</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.language_code}
                      name="language_code"
                      options={languageCodeList}
                      onChange={onLanguageCodeChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Gender</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.gender}
                      name="gender"
                      options={genderList}
                      onChange={onGenderChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Voice</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.voice}
                      name="voice"
                      options={voiceList}
                      onChange={onVoiceChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Speaking rate</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.speaking_rate}
                      name="speaking_rate"
                      options={speedList}
                      onChange={onSpeedChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Pitch</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.pitch}
                      name="pitch"
                      options={speedList}
                      onChange={onSpeedChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Extension</label>
                    <Select
                      className="basic-single selectbox-container"
                      classNamePrefix="select"
                      value={state.tts_return_option}
                      name="tts_return_option"
                      options={extensionList}
                      onChange={onExtensionChange}
                    />
                  </div>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      setLog(0);
                    }}
                  >
                    Reset log
                  </a>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="cell-button btn btn-success"
                onClick={downloadFile}
              >
                Download
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Concatenate Audio Files</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <a href="javascript:void(0)" onClick={listFilesInDirectory}>
                  List audio files
                </a>
                <div>
                  <SortableList
                    items={files}
                    onSortEnd={onSortEnd}
                    useDragHandle
                  />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button className="btn btn-info" onClick={concatenateFiles}>
                Concatenate
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
