import React, { useState, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CreateAdsIcon() {
  const initialState = {
    shopee_image: "",
    shopee_link: "",
    lazada_image: "",
    lazada_link: "",
  };
  const [state, setState] = useState(initialState);
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const createAds = () => {
    API.createItem("write_to_file", state, token)
      .then((resp) => {
        console.log(resp);
        toast("Ads information is writen in file!");
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Comment Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Comment Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Create Ads Icon</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Shopee Image</label>
                    <input
                      id="shopee_image"
                      name="shopee_image"
                      className="form-control"
                      type="text"
                      placeholder="Enter Shopee image"
                      value={state.shopee_image}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Shopee Link</label>
                    <input
                      id="shopee_link"
                      name="shopee_link"
                      className="form-control"
                      type="text"
                      placeholder="Enter Shopee link"
                      value={state.shopee_link}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Lazada Image</label>
                    <input
                      id="lazada_image"
                      name="lazada_image"
                      className="form-control"
                      type="text"
                      placeholder="Enter Lazada image"
                      value={state.lazada_image}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Lazada Link</label>
                    <input
                      id="lazada_link"
                      name="lazada_link"
                      className="form-control"
                      type="text"
                      placeholder="Enter Lazada link"
                      value={state.lazada_link}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="cell-button btn btn-success"
                onClick={createAds}
              >
                Create
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
