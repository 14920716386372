import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

// For react-dropzone
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "relative",
  width: "auto",
  height: "100%",
};

const thumbName = {
  width: 100,
};

const maxFileSize = 5242880;

function maxFileSizeValidator(file) {
  if (file.size > maxFileSize) {
    return {
      code: "file-too-large",
      message: `File size is larger than ${maxFileSize} Bytes`,
    };
  }

  return null;
}

export default function FilesUpload(props) {
  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState(props.files);
  const [rejected_files, setRejectedFiles] = useState([]);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      validator: maxFileSizeValidator,
      accept: "image/*,.pdf,.doc,.docx,.xls,.xlsx",
      onDrop: (acceptedFiles, fileRejections) => {
        const files_list = files;
        const saved_files_list = savedFiles;
        const rejected_files_list = rejected_files;
        for (var j = 0, m = fileRejections.length; j < m; j++) {
          rejected_files_list.push(fileRejections[j]);
        }
        for (var i = 0, l = acceptedFiles.length; i < l; i++) {
          const x = files_list.find(
            (item) => item.name === acceptedFiles[i].name
          );
          if (!x) {
            const y =
              saved_files_list &&
              saved_files_list.find(
                (item) =>
                  item.name === acceptedFiles[i].name &&
                  item.size === acceptedFiles[i].size
              );
            if (!y) {
              files_list.push(acceptedFiles[i]);
            } else {
              rejected_files_list.push({
                file: acceptedFiles[i],
                errors: [
                  { code: "file-duplication", message: "File is duplicated" },
                ],
              });
            }
          } else {
            rejected_files_list.push({
              file: acceptedFiles[i],
              errors: [
                { code: "file-duplication", message: "File is duplicated" },
              ],
            });
          }
        }
        files_list &&
          files_list.map((file) => {
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
          });
        setFiles(files_list);
        setRejectedFiles(rejected_files_list);
        props.getUploadedFiles(files_list);
      },
    });

  const thumbs =
    files &&
    files.map((file, i) => {
      return (
        <div key={file.name}>
          <div style={thumb}>
            <div style={thumbInner}>
              <button
                type="button"
                className="rmv"
                onClick={() => deleteFile(i)}
              >
                <i className="fas fa-times"></i>
              </button>
              <img src={file.preview} style={img} />
            </div>
          </div>
          <div style={thumbName}>{file.name}</div>
        </div>
      );
    });

  const savedThumbs =
    savedFiles &&
    savedFiles.map((file, i) => {
      return (
        <div key={file.name}>
          <div style={thumb}>
            <div style={thumbInner}>
              <button
                type="button"
                className="rmv"
                onClick={() => deleteSavedFile(i)}
              >
                <i className="fas fa-times"></i>
              </button>
              <img src={file.preview} style={img} />
            </div>
          </div>
          <div style={thumbName}>{file.name}</div>
        </div>
      );
    });

  const fileRejectionItems =
    rejected_files &&
    rejected_files.map(({ file, errors }) => {
      return (
        <li key={file.path} className="col-md-8">
          {file.path} - {file.size} bytes
          <ul>
            {errors.map((e) => (
              <li key={e.code}>{e.message}</li>
            ))}
          </ul>
        </li>
      );
    });

  const deleteFile = (i) => {
    var files_list = files.filter((file, index) => index !== i);
    setFiles(files_list);
    props.getUploadedFiles(files_list);
  };

  const deleteSavedFile = (i) => {
    var files_list = savedFiles.filter((file, index) => index !== i);
    setSavedFiles(files_list);
    props.getDeleteSavedFile(savedFiles[i]);
  };

  useEffect(
    () => () => {
      var myfiles = props.savedFiles;

      if (myfiles.length != 0 && props.isUpdating) {
        var new_files = [];
        for (var i = 0, l = myfiles.length; i < l; i++) {
          new_files.push({
            id: myfiles[i].id,
            job: myfiles[i].job,
            name: myfiles[i].name,
            size: myfiles[i].size,
            lastModifiedDate: myfiles[i].lastModifiedDate,
            preview: myfiles[i].file,
          });
        }
        setSavedFiles(new_files);
      }

      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files, props.savedFiles]
  );

  return (
    <React.Fragment>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>{savedThumbs}</aside>
      <aside style={thumbsContainer}>{thumbs}</aside>
      <aside style={thumbsContainer}>{fileRejectionItems}</aside>
    </React.Fragment>
  );
}
