import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  SortableHandle,
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";

const floatRight = {
  float: "right",
};

export default function StoryDetail() {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    title: "",
    author: "",
    slug: "",
    category: "",
    subject: "",
    body: "",
    active: false,
  };
  const [state, setState] = useState(initialState);
  const [chapters, setChapters] = useState([]);
  const [chaptersOrdering, setchaptersOrdering] = useState([]);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let chapters_arr = arrayMove(chapters, oldIndex, newIndex);
    let ordering_arr = arrayMove(chaptersOrdering, oldIndex, newIndex);
    var starter = 0;
    if (oldIndex < newIndex) {
      starter = oldIndex;
    } else {
      starter = newIndex;
    }
    for (let i = starter; i < ordering_arr.length; i++) {
      ordering_arr[i].ordering = i;
      var mychapter = { ordering: ordering_arr[i].ordering };
      API.updateItem("chapters", ordering_arr[i].id, mychapter, token)
        .then((resp) => {
          console.log("Updated: ", ordering_arr[i].id);
        })
        .catch((error) => console.log(error));
    }

    setChapters(chapters_arr);
    // setChapters(({ items }) => ({
    //   items: arrayMove(items, oldIndex, newIndex),
    // }));
  };

  const DragHandle = SortableHandle(() => (
    <div className="react-sortable-hoc-control"></div>
  ));

  const SortableItem = SortableElement(({ value, index, key }) => (
    <div className="react-sortable-hoc-item" index={index}>
      <DragHandle />
      {/* <div className="list__card-right"> */}
      <div style={{ width: "100%" }}>
        <div className="list__card-right--name">
          <Link
            to={{
              pathname: "/chapters/create/",
              state: {
                id: value.id,
                story_id: location.state.id,
                category: state.category,
                subject: state.subject,
                author: state.author,
              },
            }}
          >
            {value.title}
          </Link>{" "}
        </div>
        <div className="list__card-right--description">
          <div style={{ width: "20%", float: "left" }}>
            Active: {value.active ? "Yes" : "No"}
          </div>
          <div style={{ width: "80%", float: "right", textAlign: "right" }}>
            <Link
              to={{
                pathname: "/articles/create/",
                state: { id: value.id, isCopying: true },
              }}
              style={{ margin: "0 10px 0 10px" }}
            >
              Copy
            </Link>
            <a
              href="javascript:void(0);"
              onClick={() => onCreateAudio(value)}
              style={{ margin: "0 10px 0 10px" }}
            >
              Create audio
            </a>
            <a
              href="javascript:void(0);"
              onClick={() => onEncrypt(value)}
              style={{ margin: "0 10px 0 10px" }}
            >
              Encrypt
            </a>
          </div>
        </div>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="react-sortable-hoc-container">
        {items
          .sort((a, b) => a.position - b.position)
          .map((value, index) => (
            <SortableItem value={value} index={index} key={value.id} />
          ))}
      </div>
    );
  });

  const onCreateAudio = (obj) => {
    var link = "get_create_audio_content/" + state.id + "/" + obj.id + "/";
    API.getItems(link, token)
      .then((resp) => {
        var params = {};
        params["file_name"] = state.slug + "-" + (resp.ordering + 1).toString();
        // params["content"] = resp.title + ". \n" + resp.notes + "\n Hết chương.";
        // params["content"] =
        //   resp.title +
        //   ". \n" +
        //   "Nguồn, gac audios chấm com. \n" +
        //   resp.notes +
        //   "\n Hết chương.";
        params["content"] =
          resp.title +
          ". \n" +
          "Nguồn, gac audios chấm com. \n" +
          resp.notes;
        params["folder"] = state.slug;

        API.createItem("synthesize_long_audio", params, token)
          .then((resp) => {
            fetch(resp.path).then((response) => {
              response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = resp.fileName;
                a.click();
              });
            });

            toast(resp.message);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const onEncrypt = (obj) => {
    var link = "encrypt_body/" + obj.id.toString() + "/";
    API.getItems(link, token)
      .then((resp) => {
        toast("Encrypted body!");
      })
      .catch((error) => console.log(error));
  };

  const deleteItem = () => {
    if (location.state) {
      if (location.state.id) {
        if (window.confirm("Are you sure you wish to delete this item?"))
          API.updateItem(
            "articles",
            location.state.id,
            { active: false },
            token
          )
            .then((resp) => {
              history.push({
                pathname: "/stories/",
              });
            })
            .catch((error) => console.log(error));
      }
    }
  };

  const onUpdate = () => {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: "/articles/create/",
          state: { id: location.state.id },
        });
      }
    }
  };

  const onAddChapter = () => {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: "/chapters/create/",
          state: {
            story_id: location.state.id,
            category: state.category,
            subject: state.subject,
            author: state.author,
          },
        });
      }
    }
  };

  const onAddExistChapter = () => {
    if (location.state) {
      if (location.state.id) {
        history.push({
          pathname: "/chapters/add_exist_chapter/",
          state: {
            parents_article: location.state.id,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        API.getItem("articles", location.state.id, token)
          .then((resp) => {
            setState({
              ...state,
              id: resp.id,
              title: resp.title,
              author: resp.author,
              slug: resp.slug,
              category: resp.category,
              subject: resp.subject,
              active: resp.active,
              body: resp.body,
            });
            var link = "get_chapters_by_story_id/" + resp.id.toString() + "/";
            API.getItems(link, token)
              .then((resp1) => {
                setChapters(resp1.articles);
                setchaptersOrdering(resp1.chapters);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Company Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Story Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="card-title">
                    {state.id} - {state.title}
                  </h3>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                    onClick={onAddChapter}
                  >
                    Add new chapter
                  </button>
                  <button
                    className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                    onClick={onAddExistChapter}
                  >
                    Add exist chapter
                  </button>
                  <button
                    className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                    onClick={() =>
                      history.push({
                        pathname: "/stories/",
                      })
                    }
                  >
                    Back
                  </button>
                  {/* <button
                    className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                    onClick={onUpdate}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                    onClick={deleteItem}
                  >
                    Delete
                  </button> */}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                  <div className="row">
                    <div className="col-12">
                      <h5>Chapters list</h5>
                      {/* <SortableList onSortEnd={onSortEnd} useDragHandle>
                        {chapters &&
                          chapters.map((value, index) => (
                            <SortableItem
                              key={`item-${value}`}
                              index={index}
                              value={value}
                            />
                          ))}
                      </SortableList> */}
                      <SortableList
                        items={chapters}
                        onSortEnd={onSortEnd}
                        useDragHandle
                      />
                      {/* {chapters &&
                        chapters.map((chapter, i) => (
                          <div className="post" key={i}>
                            <div className="user-block">
                              <span className="username">
                                <Link
                                  to={{
                                    pathname: "/chapters/create/",
                                    state: {
                                      id: chapter.id,
                                      story_id: location.state.id,
                                      category: state.category,
                                    },
                                  }}
                                >
                                  {chapter.title}
                                </Link>
                              </span>
                              <span className="description">
                                Active: {chapter.active ? "Yes" : "No"}
                              </span>
                            </div>
                          </div>
                        ))} */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                  <h3 className="text-primary">
                    <i className="fas fa-paint-brush"></i> {state.title}
                  </h3>
                  <p className="text-muted">{ReactHtmlParser(state.body)}</p>
                  <br />
                  <div className="text-muted">
                    <p className="text-sm">
                      <b className="d-block">Category</b>
                      {state.category}
                    </p>
                    <p className="text-sm">
                      <b className="d-block">Is active?</b>
                      {state.active ? "Yes" : "No"}
                    </p>
                  </div>

                  <div className="text-center mt-5 mb-3">
                    {/* <button
                      className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                      onClick={onAddChapter}
                    >
                      Add new chapter
                    </button>
                    <button
                      className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                      onClick={onAddExistChapter}
                    >
                      Add exist chapter
                    </button> */}
                    <button
                      className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                      onClick={onUpdate}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                      onClick={deleteItem}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                onClick={onAddChapter}
              >
                Add new chapter
              </button>
              <button
                className="btn btn-sm btn-success ustorekit-common-margin-10-right"
                onClick={onAddExistChapter}
              >
                Add exist chapter
              </button>
              <button
                className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                onClick={() =>
                  history.push({
                    pathname: "/stories/",
                  })
                }
              >
                Back
              </button>
              <button
                className="btn btn-sm btn-primary ustorekit-common-margin-10-right"
                onClick={onUpdate}
              >
                Update
              </button>
              <button
                className="btn btn-sm btn-warning ustorekit-common-margin-10-right"
                onClick={deleteItem}
              >
                Delete
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
