import React, { useState, useEffect, useContext } from "react";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters } from "react-data-grid-addons";
import "../common/custom_css.css";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import { useHistory } from "react-router-dom";

const {
  DraggableHeader: { DraggableContainer },
} = require("react-data-grid-addons");
const selectors = Data.Selectors;
const {
  NumericFilter,
  AutoCompleteFilter,
  MultiSelectFilter,
  SingleSelectFilter,
} = Filters;

const EmptyRowsView = () => {
  const message = "No data to show";
  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
    >
      <img src="./logo.png" alt={message} />
      <h3>{message}</h3>
    </div>
  );
};

const handleFilterChange = (filter) => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getValidFilterValues(rows, columnId) {
  return rows
    .map((r) => r[columnId])
    .filter((item, i, a) => {
      return i === a.indexOf(item);
    });
}

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}

export default function Genres() {
  const history = useHistory();
  const COLUMN_WIDTH = 250;
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  // Action Box
  const actionsList = [
    { value: 1, label: "Delete selected items" },
    { value: 2, label: "Active selected items" },
  ];
  const [selectedAction, setSelectedAction] = useState();

  const columns_db = [
    {
      key: "id",
      name: "ID",
      width: 150,
      filterable: true,
      frozen: true,
    },
    {
      key: "name",
      name: "Name",
      width: COLUMN_WIDTH,
      resizable: true,
      filterable: true,
    },
    {
      key: "slug",
      name: "Slug",
      width: COLUMN_WIDTH,
      resizable: true,
      filterable: true,
    },
    {
      key: "site",
      name: "Site",
      width: COLUMN_WIDTH,
      resizable: true,
      filterable: true,
    },
    {
      key: "active",
      name: "Is Active",
      width: 150,
      resizable: true,
      filterable: true,
    },
  ];

  const initialState = {
    columns: [],
    rows: [],
  };

  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [state, setState] = useState(initialState);
  //   const rowText = selectedIndexes.length === 1 ? "row" : "rows";

  const [filters, setFilters] = useState({});
  const filteredRows = getRows(state.rows, filters);

  const deleteRow = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?"))
      API.updateItem("genres", id, { active: false }, token)
        .then((resp) => {
          API.getItems("genres/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns_db);
              setState({
                ...state,
                columns: columns_db,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/genres/");
        })
        .catch((error) => console.log(error));
  };

  const getCellActions = (column, row) => {
    if (column.key === "id") {
      return [
        {
          icon: "fa fa-times",
          callback: () => {
            deleteRow(row.id);
          },
        },
        {
          icon: "fa fa-edit",
          callback: () => {
            history.push({
              pathname: "/genres/create/",
              state: { id: row.id },
            });
          },
        },
      ];
    }
  };

  const onRowsSelected = (rows) => {
    setSelectedIndexes(selectedIndexes.concat(rows.map((r) => r.rowIdx)));
  };

  const onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    setSelectedIndexes(
      selectedIndexes.filter((i) => rowIndexes.indexOf(i) === -1)
    );
  };

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            switch (col.key) {
              case "active":
                if (row[col.key]) {
                  validrow[col.key] = "Yes";
                } else {
                  validrow[col.key] = "No";
                }
                break;
              default:
                validrow[col.key] = row[col.key];
                validrow["id"] = row["id"];
            }
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  const newRow = () => {
    history.push({
      pathname: "/genres/create/",
    });
  };

  useEffect(() => {
    API.getItems("genres", token)
      .then((resp) => {
        const rows = createRowsList(resp, columns_db);
        setState({
          ...state,
          columns: columns_db,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Genres</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Genres</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            {/* <div className="col-sm-6" style={selectStore}>
                <StoreSelection />
              </div> */}
            <div className="card-body">
              <div className="toolbar row ustorekit-common-under-margin-10">
                <div className="col-md-2">
                  <button
                    className="cell-button btn btn-success"
                    onClick={newRow}
                  >
                    Add a genre
                  </button>
                </div>
              </div>
              <span>{/* {selectedIndexes.length} {rowText} selected */}</span>
              <div className="relativeWrapper">
                <div className="absoluteWrapper"></div>
                {/* <DraggableContainer onHeaderDrop={onHeaderDrop}> */}
                <DraggableContainer>
                  <ReactDataGrid
                    rowKey="id"
                    columns={state.columns}
                    rowGetter={(i) => filteredRows[i]}
                    rowsCount={filteredRows.length}
                    minHeight={500}
                    getCellActions={getCellActions}
                    // onGridRowsUpdated={onGridRowsUpdated}
                    // enableCellSelect={true}
                    emptyRowsView={EmptyRowsView}
                    // onGridSort={(sortColumn, sortDirection) =>
                    //   setState([
                    //     ...state,
                    //     { rows: sortRows(state.rows, sortColumn, sortDirection) },
                    //   ])
                    // }
                    // rowSelection={{
                    //   showCheckbox: true,
                    //   enableShiftSelect: true,
                    //   onRowsSelected: onRowsSelected,
                    //   onRowsDeselected: onRowsDeselected,
                    //   selectBy: {
                    //     indexes: selectedIndexes,
                    //   },
                    // }}
                    toolbar={<Toolbar enableFilter={true} />}
                    onAddFilter={(filter) =>
                      setFilters(handleFilterChange(filter))
                    }
                    onClearFilters={() => setFilters({})}
                    getValidFilterValues={(columnKey) =>
                      getValidFilterValues(state.rows, columnKey)
                    }
                  />
                </DraggableContainer>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
