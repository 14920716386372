import React, { useState, useEffect, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import AuthContext from "../common/AuthContext";
import { API } from "../common/api-service";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { dataCommonFunction } from "../common/data-common-function";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function Articles() {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  const deleteRow = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?"))
      API.updateItem("article_active", id, { active: false }, token)
        .then((resp) => {
          API.getItems("articles/", token)
            .then((resp2) => {
              const rows = createRowsList(resp2, columns);
              setState({
                ...state,
                columns: columns,
                rows: rows,
              });
            })
            .catch((error) => console.log(error));
          history.push("/articles/");
        })
        .catch((error) => console.log(error));
  };

  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 200,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<ArticleIcon />}
              label="Edit"
              className="textPrimary"
              component={Link}
              to={{
                pathname: `/articles/detail/`,
                state: { id: params.id },
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<FileCopyIcon />}
              label="Edit"
              className="textPrimary"
              component={Link}
              to={{
                pathname: `/articles/create/`,
                state: { id: params.id, isCopying: true },
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              component={Link}
              to={{
                pathname: `/articles/create/`,
                state: { id: params.id },
              }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => deleteRow(params.id)}
              color="inherit"
            />,
          ];
        },
      },
      { field: "id", headerName: "ID", width: 100 },
      { field: "title", headerName: "Title", width: 400 },
      { field: "category", headerName: "Category", width: 100 },
      { field: "active", headerName: "Is active?", width: 100 },
      { field: "is_finished", headerName: "Is finished?", width: 100 },
      { field: "is_parent", headerName: "Is parent?", width: 100 },
    ],
    [deleteRow]
  );

  const initialState = {
    columns: [],
    rows: [],
  };
  const [state, setState] = useState(initialState);

  // Site
  const sites_db = useFetch(`${process.env.REACT_APP_API_URL}/sites/`, "GET");
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState([]);

  function createRowsList(rows, cols) {
    let validrows = [];
    rows &&
      rows.forEach((row) => {
        let validrow = {};
        cols &&
          cols.forEach((col) => {
            switch (col.field) {
              case "active":
              case "is_parent":
              case "is_finished":
                if (row[col.field]) {
                  validrow[col.field] = "✔";
                } else {
                  validrow[col.field] = "×";
                }
                break;
              default:
                validrow[col.field] = row[col.field];
                validrow["id"] = row["id"];
            }
          });
        validrows.push(validrow);
      });
    return validrows;
  }

  const newRow = () => {
    history.push({
      pathname: "/articles/create/",
    });
  };

  const onSiteChange = (newValue, actionMeta) => {
    setSelectedSite(newValue);

    var link =
      "get_articles_by_site_id_backend/" + newValue.value.toString() + "/";
    API.getItems(link, token)
      .then((resp) => {
        const rows = createRowsList(resp.articles, columns);
        setState({
          ...state,
          columns: columns,
          rows: rows,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    // Sites
    console.log("sites_db", sites_db);
    const site_options = dataCommonFunction.createSelectOption(
      sites_db.response
    );
    setSites(site_options);

    if (site_options.length > 0) {
      setSelectedSite(site_options[0]);

      var link =
        "get_articles_by_site_id_backend/" +
        site_options[0].value.toString() +
        "/";
      API.getItems(link, token)
        .then((resp) => {
          const rows = createRowsList(resp.articles, columns);
          setState({
            ...state,
            columns: columns,
            rows: rows,
          });
        })
        .catch((error) => console.log(error));
    } else {
      API.getItems("articles", token)
        .then((resp) => {
          const rows = createRowsList(resp, columns);
          setState({
            ...state,
            columns: columns,
            rows: rows,
          });
        })
        .catch((error) => console.log(error));
    }
  }, [sites_db.response]);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Articles</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Articles</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            {/* <div className="col-sm-6" style={selectStore}>
                <StoreSelection />
              </div> */}
            <div className="card-body">
              <div className="toolbar row ustorekit-common-under-margin-10">
                <div className="col-md-2">
                  <button
                    className="cell-button btn btn-success"
                    onClick={newRow}
                  >
                    Add an article
                  </button>
                </div>
              </div>
              <div className="form-group">
                <label>Site</label>
                <Select
                  className="basic-single selectbox-container"
                  classNamePrefix="select"
                  value={selectedSite}
                  name="site"
                  options={sites}
                  onChange={onSiteChange}
                />
              </div>
              <span>{/* {selectedIndexes.length} {rowText} selected */}</span>
              <div className="relativeWrapper">
                <div className="absoluteWrapper"></div>
                <DataGrid
                  rows={state.rows}
                  columns={columns}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                  }}
                  pageSizeOptions={[15, 25, 50, 100]}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
