import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import useFetch from "../../hooks/useFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { commonFunction } from "../common/common-functions";
import { dataCommonFunction } from "../common/data-common-function";
import { commonVariables } from "../common/common-variables";
import FilesUpload from "../common/FilesUpload";
import FileUploadService from "../common/FileUploadService";
import MyEditor from "../common/MyEditor";
import { WithContext as ReactTags } from "react-tag-input";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function ArticleForm(props) {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    title: "",
    author: "",
    slug: "",
    category: "",
    body: "",
    notes: "",
    tags: "",
    active: false,
    is_parent: false,
    video_url: "",
    subject: "",
    audio_url: "",
    is_finished: false,
  };
  const [state, setState] = useState(initialState);

  // Sites
  const sites_db = useFetch(`${process.env.REACT_APP_API_URL}/sites/`, "GET");
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState([]);

  //Genres
  // const genres_db = useFetch(`${process.env.REACT_APP_API_URL}/genres/`, "GET");
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);

  //Parent
  const [parents, setParents] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  const [selectedChapterId, setSelectedChapterId] = useState(0);

  //Authors
  const authors_db = useFetch(
    `${process.env.REACT_APP_API_URL}/authors/`,
    "GET"
  );
  const [authorOptions, setAuthorOptions] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState([]);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);
  const [isChanged, setIsChanged] = useState(false);
  const isDisabled =
    state.title.length === 0 || state.slug.length === 0 || !isChanged;

  // Files
  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [deleteSavedFiles, setDeleteSavedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  // Tags
  const [tags, setTags] = useState([]);

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const handleSlugChange = (evt) => {
    const slug = commonFunction.slugify(evt.target.value);
    setState({
      ...state,
      title: evt.target.value,
      slug: slug,
    });
    setIsChanged(true);
  };

  const onSiteChange = (newValue, actionMeta) => {
    setSelectedSite(newValue);
    setState({
      ...state,
      site: newValue.value,
    });
    var link = "get_categories_by_site_id/" + newValue.value.toString() + "/";
    API.getItems(link, token)
      .then((resp) => {
        if (resp.categories && resp.categories.length > 0) {
          const category_options = dataCommonFunction.createSelectOption(
            resp.categories
          );
          setCategories(category_options);
        } else {
          setCategories([]);
        }
      })
      .catch((error) => console.log(error));
    var link = "get_genres_by_site_id/" + newValue.value.toString() + "/";
    API.getItems(link, token)
      .then((resp2) => {
        if (resp2.genres && resp2.genres.length > 0) {
          const genre_options = dataCommonFunction.createSelectOption(
            resp2.genres
          );
          setGenres(genre_options);
          setSelectedGenres([]);
        } else {
          setGenres([]);
        }
      })
      .catch((error) => console.log(error));
    setIsChanged(true);
    console.groupEnd();
  };

  const onCategoryChange = (newValue, actionMeta) => {
    setSelectedCategory(newValue);
    setState({
      ...state,
      category: newValue.value,
    });
    setIsChanged(true);
    console.groupEnd();
  };

  const onParentChange = (newValue, actionMeta) => {
    setSelectedParent(newValue);
    setIsChanged(true);
    console.groupEnd();
  };

  const onGenreChange = (newValue, actionMeta) => {
    setSelectedGenres(newValue);
    setIsChanged(true);
    console.groupEnd();
  };

  //Author
  const onAuthorChange = (newValue, actionMeta) => {
    setSelectedAuthor(newValue);
    setState({
      ...state,
      author: newValue.value,
    });
    setIsChanged(true);
    console.groupEnd();
  };

  const handleAuthorCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const author = {
        name: inputValue,
        slug: commonFunction.slugify(inputValue),
        active: true,
      };
      API.createItem("authors", author, token)
        .then((resp) => {
          API.getItems("authors", token)
            .then((resp1) => {
              const author_options = dataCommonFunction.createSelectOption(
                authors_db.response
              );
              // const newOption = {
              //   label: inputValue,
              //   value: commonFunction.slugify(inputValue),
              // };
              setAuthorOptions(author_options);
              // setSelectedAuthor(newOption);
              setIsLoading(false);
            })
            .catch((error) => console.log(error));
          const newOption = {
            label: resp.name,
            value: resp.id,
          };
          setSelectedAuthor(newOption);
          setState({
            ...state,
            author: resp.id,
          });
        })
        .catch((error) => console.log(error));

      console.groupEnd();
    }, 1000);
  };

  const handleGenreCreate = (inputValue) => {
    if (!selectedSite || Object.keys(selectedSite).length < 1) {
      window.confirm("You have to choose site!");
    } else {
      setIsLoading(true);
      setTimeout(() => {
        const genre = {
          name: inputValue,
          slug: commonFunction.slugify(inputValue),
          active: true,
          site: selectedSite.value,
        };
        API.createItem("genres", genre, token)
          .then((resp) => {
            var link =
              "get_genres_by_site_id/" + selectedSite.value.toString() + "/";
            API.getItems(link, token)
              .then((resp1) => {
                const genre_options = dataCommonFunction.createSelectOption(
                  resp1.genres
                );
                // const newOption = {
                //   label: inputValue,
                //   value: commonFunction.slugify(inputValue),
                // };
                setGenres(genre_options);
                // setSelectedAuthor(newOption);
                setIsLoading(false);
              })
              .catch((error) => console.log(error));
            const newOption = {
              label: resp.name,
              value: resp.id,
            };
            var list = selectedGenres;
            list.push(newOption);
            setSelectedGenres(list);
            setIsChanged(true);
          })
          .catch((error) => console.log(error));

        console.groupEnd();
      }, 1000);
    }
  };

  const onSubjectChange = (newValue, actionMeta) => {
    setSelectedSubject(newValue);
    setState({
      ...state,
      subject: newValue.value,
    });
    setIsChanged(true);
    console.groupEnd();
  };

  const getUploadedFiles = (list) => {
    setFiles(list);
    setIsChanged(true);
  };

  const getDeleteSavedFile = (file) => {
    var files = deleteSavedFiles;
    files.push(file);
    setDeleteSavedFiles(files);
    setIsChanged(true);
  };

  // Tags
  const handleTagsDelete = (i) => {
    var tagslist = tags.filter((tag, index) => index !== i);
    setTags(tagslist);
    setState({ ...state, tags: commonFunction.connectToList(tagslist) });
    setIsChanged(true);
  };

  const handleTagsAddition = (tag) => {
    var tagslist = tags;
    setTags([...tags, tag]);
    tagslist.push(tag);
    setState({ ...state, tags: commonFunction.connectToList(tagslist) });
    setIsChanged(true);
  };

  const createClicked = () => {
    var body = state;
    body.category = selectedCategory.value;
    API.createItem("articles", body, token)
      .then((resp) => {
        setProgress(0);
        if (files.length != 0) {
          FileUploadService.upload(
            "article_files",
            files,
            token,
            "article",
            resp.id,
            (event) => {
              const percentage = Math.round((100 * event.loaded) / event.total);
              setProgress(percentage);
              if (percentage == 100) {
                setFiles([]);
              }
            }
          );
        }

        savedFiles.map((sf) => {
          if (deleteSavedFiles.findIndex((df) => df.id === sf.id) == -1) {
            sf.article = resp.id;
            API.callAnAction(
              "copy_article_files",
              sf.id,
              sf,
              "copy_article_file",
              token
            )
              .then((resp2) => {})
              .catch((error) => console.log(error));
          }
        });

        if (resp.id) {
          selectedGenres &&
            selectedGenres.map((sg) => {
              var ag = {
                article: resp.id,
                genre: sg.value,
              };
              API.createItem("article_genres", ag, token)
                .then((resp1) => {})
                .catch((error) => console.log(error));
            });
        }

        history.push("/articles/");
      })
      .catch((error) => console.log(error));
  };

  const updateClicked = () => {
    // if (location.state && isChanged) {
    if (location.state) {
      API.updateItem("articles", location.state.id, state, token)
        .then((resp) => {
          if (files.length != 0) {
            FileUploadService.upload(
              "article_files",
              files,
              token,
              "article",
              location.state.id,
              (event) => {
                const percentage = Math.round(
                  (100 * event.loaded) / event.total
                );
                setProgress(percentage);
                if (percentage == 100) {
                  setFiles([]);
                }
              }
            );
          }
          if (deleteSavedFiles.length != 0) {
            deleteSavedFiles.map((file) => {
              API.deleteItem("article_files", file.id, token)
                .then((resp2) => {
                  console.log("Deleted files", resp2);
                })
                .catch((error) => console.log(error));
            });
            setDeleteSavedFiles([]);
          }

          if (resp.id) {
            var link =
              "delete_article_genres_by_article_id/" + resp.id.toString() + "/";
            API.getItems(link, token)
              .then((resp3) => {
                selectedGenres &&
                  selectedGenres.map((sg) => {
                    var ag = {
                      article: resp.id,
                      genre: sg.value,
                    };
                    API.createItem("article_genres", ag, token)
                      .then((resp1) => {
                        console.log("Created article genres");
                      })
                      .catch((error) => console.log(error));
                  });
              })
              .catch((error) => console.log(error));
          }

          history.push("/articles/");
        })
        .catch((error) => console.log(error));
    }
  };

  const updateParentClicked = () => {
    API.updateItem(
      "chapters",
      selectedChapterId,
      { parents_article: selectedParent.value },
      token
    )
      .then((resp) => {
        console.log("Updated parent.");
      })
      .catch((error) => console.log(error));
  };

  const onCancel = () => {
    // if (location.state) {
    //   if (location.state.jobid) {
    //     history.push({
    //       pathname: "/jobs/detail/",
    //       state: { id: location.state.jobid },
    //     });
    //   } else {
    //     history.push("/sites/");
    //   }
    // } else {
    //   history.push("/sites/");
    // }
    history.push("/articles/");
  };

  useEffect(() => {
    // Sites
    const site_options = dataCommonFunction.createSelectOption(
      sites_db.response
    );
    setSites(site_options);

    //Genres
    // const genre_options = dataCommonFunction.createSelectOption(
    //   genres_db.response
    // );
    // setGenreOptions(genre_options);

    //Author
    const author_options = dataCommonFunction.createSelectOption(
      authors_db.response
    );
    setAuthorOptions(author_options);

    setIsUpdating(false);

    if (location.state) {
      if (location.state.id) {
        var article_id = location.state.id;
        if (location.state.isCopying) {
          setIsUpdating(false);
          setIsCopying(true);
        } else {
          setIsUpdating(true);
        }
        // setIsUpdating(true);
        setIsChanged(false);
        API.getItem("articles", article_id, token)
          .then((resp) => {
            // setState({...state, title: resp.title,});
            // setState({...state, slug: resp.slug,});
            setState((prevState, props) => ({
              id: resp.id,
              title: resp.title,
              author: resp.author,
              slug: resp.slug,
              category: resp.category,
              tags: resp.tags,
              active: resp.active,
              is_parent: resp.is_parent,
              video_url: resp.video_url,
              subject: resp.subject,
              audio_url: resp.audio_url,
              is_finished: resp.is_finished,
              body: resp.body,
              notes: resp.notes,
            }));
            // setState({
            //   ...state,
            //   id: resp.id,
            //   title: resp.title,
            //   slug: resp.slug,
            //   category: resp.category,
            //   tags: resp.tags,
            //   active: resp.active,
            //   is_parent: resp.is_parent,
            //   video_url: resp.video_url,
            //   subject: resp.subject,
            //   body: resp.body,
            //   notes: resp.notes,
            // });
            // setState({...state, body: resp.body,});
            // setState({...state, notes: resp.notes,});

            if (resp.tags && resp.tags.length != 0) {
              setTags(commonFunction.splitListToTags(resp.tags));
            }

            setSelectedSubject(
              commonVariables.SUBJECT_OPTIONS.find((element) => {
                return element.value === resp.subject;
              })
            );

            var category_id = resp.category;
            API.getItem("categories", category_id, token)
              .then((resp2) => {
                setSelectedSite(
                  site_options.find((element) => {
                    return element.value === resp2.site;
                  })
                );

                var link =
                  "get_categories_by_site_id/" + resp2.site.toString() + "/";
                API.getItems(link, token)
                  .then((resp1) => {
                    if (resp1.categories && resp1.categories.length > 0) {
                      const category_options =
                        dataCommonFunction.createSelectOption(resp1.categories);
                      setCategories(category_options);
                      setSelectedCategory(
                        category_options.find((element) => {
                          return element.value === resp2.id;
                        })
                      );
                    } else {
                      setCategories([]);
                    }
                  })
                  .catch((error) => console.log(error));

                var link =
                  "get_genres_by_site_id/" + resp2.site.toString() + "/";
                API.getItems(link, token)
                  .then((resp5) => {
                    if (resp5.genres && resp5.genres.length > 0) {
                      const genre_options =
                        dataCommonFunction.createSelectOption(resp5.genres);
                      setGenres(genre_options);
                    } else {
                      setGenres([]);
                    }
                  })
                  .catch((error) => console.log(error));

                if (!resp.is_parent) {
                  var link =
                    "get_all_parent_articles_by_site_id/" +
                    resp2.site.toString() +
                    "/";
                  API.getItems(link, token)
                    .then((resp7) => {
                      var parentOptions =
                        dataCommonFunction.createSelectOptionByTitle(
                          resp7.articles
                        );
                      setParents(parentOptions);
                    })
                    .catch((error) => console.log(error));
                  var link =
                    "get_parent_article_by_article_id/" +
                    resp.id.toString() +
                    "/";
                  API.getItems(link, token)
                    .then((resp8) => {
                      var parentOption = {
                        value: resp8.article.id,
                        label: resp8.article.title,
                      };
                      setSelectedParent(parentOption);
                      setSelectedChapterId(resp8.chapter.id);
                    })
                    .catch((error) => console.log(error));
                }

                // var link =
                //   "get_categories_by_site_id_except_children/" +
                //   resp2.site.toString() +
                //   "/" +
                //   resp2.id.toString() +
                //   "/";
                // API.getItems(link, token)
                //   .then((resp1) => {
                //     if (resp1.categories && resp1.categories.length > 0) {
                //       const category_options =
                //         dataCommonFunction.createSelectOption(resp1.categories);
                //       setCategories(category_options);
                //       if (resp.parent) {
                //         setSelectedCategory(
                //           category_options.find((element) => {
                //             return element.value === resp.parent;
                //           })
                //         );
                //       }
                //     }
                //   })
                //   .catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));

            var link = "articlefiles/" + resp.id.toString() + "/";
            API.getItems(link, token)
              .then((resp3) => {
                setSavedFiles(resp3.files);
              })
              .catch((error) => console.log(error));

            var link = "get_genres_by_article_id/" + resp.id.toString() + "/";
            API.getItems(link, token)
              .then((resp6) => {
                var genreOptions = dataCommonFunction.createSelectOption(
                  resp6.genres
                );
                setSelectedGenres(genreOptions);
              })
              .catch((error) => console.log(error));

            API.getItem("authors", resp.author, token)
              .then((resp4) => {
                if (resp4.id) {
                  setSelectedAuthor({ value: resp4.id, label: resp4.name });
                }
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, [sites_db.response, authors_db.response]);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Article</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Article</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id
                  ? "Update Article"
                  : "New Article"}
              </h3>
              <div className="card-tools">
                {location.state &&
                location.state.id &&
                !location.state.isCopying ? (
                  <a
                    href="javascript:void(0)"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={updateClicked}
                    // disabled={isDisabled}
                  >
                    Update
                  </a>
                ) : (
                  <a
                    href="javascript:void(0)"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={createClicked}
                    disabled={isDisabled}
                  >
                    Create
                  </a>
                )}
                <a
                  href="javascript:void(0)"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to discard changes?"
                      )
                    )
                      onCancel();
                  }}
                >
                  Cancel
                </a>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title</label>
                    {location.state && location.state.id ? (
                      <input
                        id="title"
                        name="title"
                        className="form-control"
                        type="text"
                        placeholder="Enter title"
                        value={state.title}
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        id="title"
                        name="title"
                        className="form-control"
                        type="text"
                        placeholder="Enter title"
                        value={state.title}
                        onChange={handleSlugChange}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Site</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedSite}
                      name="site"
                      options={sites}
                      onChange={onSiteChange}
                      placeholder="Select site"
                    />
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedSubject}
                      name="parent"
                      options={commonVariables.SUBJECT_OPTIONS}
                      onChange={onSubjectChange}
                      placeholder="Select subject"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Slug</label>
                    <input
                      id="slug"
                      name="slug"
                      className="form-control"
                      type="text"
                      placeholder="Enter slug"
                      value={state.slug}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Category</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedCategory}
                      name="parent"
                      options={categories}
                      onChange={onCategoryChange}
                      placeholder="Select category"
                    />
                  </div>
                  <div className="form-group">
                    <label>Genre</label>
                    <CreatableSelect
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      isClearable
                      isLoading={isLoading}
                      value={selectedGenres}
                      name="genres"
                      options={genres}
                      onChange={onGenreChange}
                      onCreateOption={handleGenreCreate}
                      placeholder="Select genre"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    <label>Content</label>
                    {/* <textarea
                      className="form-control"
                      id="body"
                      name="body"
                      cols="100"
                      rows="6"
                      defaultValue={state.body}
                      placeholder="Enter content"
                      onChange={handleChange}
                    ></textarea> */}
                    <MyEditor
                      handleChange={(data) => {
                        setState({ ...state, body: data });
                      }}
                      token={token}
                      path="article_files"
                      data={state.body}
                      {...props}
                    />
                    {/* <MyQuill
                      handleChange={(data) => {
                        setState({ ...state, body: data });
                      }}
                      data={state.body}
                      token={token}
                      id="body"
                      name="body"
                    ></MyQuill> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Tag</label>
                    <ReactTags
                      id="tag"
                      tags={tags}
                      delimiters={delimiters}
                      handleDelete={handleTagsDelete}
                      handleAddition={handleTagsAddition}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Video URL</label>
                    <input
                      id="video_url"
                      name="video_url"
                      className="form-control"
                      type="text"
                      placeholder="Enter video url"
                      value={state.video_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Author</label>
                    {/* <input
                      id="author"
                      name="author"
                      className="form-control"
                      type="text"
                      placeholder="Enter author"
                      value={state.author}
                      onChange={handleChange}
                    /> */}
                    <CreatableSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isLoading={isLoading}
                      value={selectedAuthor}
                      name="author"
                      options={authorOptions}
                      onChange={onAuthorChange}
                      onCreateOption={handleAuthorCreate}
                      placeholder="Select author"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Audio URL</label>
                    <input
                      id="audio_url"
                      name="audio_url"
                      className="form-control"
                      type="text"
                      placeholder="Enter audio url"
                      value={state.audio_url}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      {/* {state.active ? (
                        <input
                          type="checkbox"
                          checked
                          id="active"
                          name="active"
                          onChange={handleChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          id="active"
                          name="active"
                          onChange={handleChange}
                        />
                      )} */}
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                  <div className="form-group clearfix">
                    <label>Is finished?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.is_finished}
                        id="is_finished"
                        name="is_finished"
                        onChange={handleChange}
                      />
                      <label htmlFor="is_finished"></label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group clearfix">
                    <label>Is parent?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      {/* {state.is_parent ? (
                        <input
                          type="checkbox"
                          checked
                          id="is_parent"
                          name="is_parent"
                          onChange={handleChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          id="is_parent"
                          name="is_parent"
                          onChange={handleChange}
                        />
                      )} */}
                      <input
                        type="checkbox"
                        checked={state.is_parent}
                        id="is_parent"
                        name="is_parent"
                        onChange={handleChange}
                      />
                      <label htmlFor="is_parent"></label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    <label>Notes</label>
                    {/* <MyEditor
                      handleChange={(data2) => {
                        setState({ ...state, notes: data2 });
                      }}
                      token={token}
                      path="article_files"
                      data={state.notes}
                      {...props}
                    /> */}
                    <textarea
                      className="form-control"
                      id="notes"
                      name="notes"
                      cols="100"
                      rows="6"
                      defaultValue={state.notes}
                      placeholder="Enter notes"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Thumbnail</label>
                    <FilesUpload
                      getUploadedFiles={getUploadedFiles}
                      getDeleteSavedFile={getDeleteSavedFile}
                      savedFiles={savedFiles}
                      isUpdating={isUpdating || isCopying}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {/* <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  disabled={isDisabled}
                >
                  Update
                </button> */}
              {location.state &&
              location.state.id &&
              !location.state.isCopying ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  // disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to discard changes?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          {location.state && location.state.id && !state.is_parent ? (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Update Parent</h3>

                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Parent</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedParent}
                        name="parent"
                        options={parents}
                        onChange={onParentChange}
                        placeholder="Select parent"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-info"
                  onClick={updateParentClicked}
                  // disabled={isDisabled}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
