import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { commonFunction } from "../common/common-functions";
import { commonVariables } from "../common/common-variables";
import { dataCommonFunction } from "../common/data-common-function";
import MyEditor from "../common/MyEditor";
import Select from "react-select";

// For react-select
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

export default function ChapterForm(props) {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    id: "",
    title: "",
    author: location.state.author,
    slug: "",
    category: location.state.category,
    body: "",
    notes: "",
    tags: "",
    active: false,
    is_parent: false,
    video_url: "",
    audio_url: "",
    is_finished: false,
    subject: location.state.subject,
  };
  const [state, setState] = useState(initialState);
  const [body, setBody] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState([]);
  const [ordering, setOrdering] = useState(0);
  const [chapterid, setChapterid] = useState("");

  //Parent
  const [parents, setParents] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  // const [selectedChapterId, setSelectedChapterId] = useState(0);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);
  const [isChanged, setIsChanged] = useState(false);
  const isDisabled =
    state.title.length === 0 || state.slug.length === 0 || !isChanged;
  const [isUpdating, setIsUpdating] = useState(false);

  const [count, setCount] = useState(0);

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setIsChanged(true);
  };

  const handleNotesChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setCount(evt.target.value.length);
    setIsChanged(true);
  };

  const handleOrderingChange = (evt) => {
    setOrdering(evt.target.value);
    setIsChanged(true);
  };

  const handleSlugChange = (evt) => {
    const slug = commonFunction.slugify(evt.target.value);
    setState({
      ...state,
      title: evt.target.value,
      slug: slug,
    });
    setIsChanged(true);
  };

  const onSubjectChange = (newValue, actionMeta) => {
    setSelectedSubject(newValue);
    setState({
      ...state,
      subject: newValue.value,
    });
    setIsChanged(true);
    console.groupEnd();
  };

  const onVoiceChange = (newValue, actionMeta) => {
    setSelectedVoice(newValue);
    console.groupEnd();
  };

  const createAudio = () => {
    var index = ordering + 1;
    var params = {};
    params["file_name"] = state.slug + "_" + index.toString() + ".mp3";
    params["content"] = state.title + ". \n" + state.notes + "\n Hết chương.";
    params["voice"] = selectedVoice.label;

    API.createItem("create_audio_file2", params, token)
      .then((resp) => {
        console.log("Audio file is created!");
        toast("Audio file is created!");
      })
      .catch((error) => console.log(error));
  };

  const createClicked = () => {
    API.createItem("articles", state, token)
      .then((resp) => {
        var chapter = {
          parents_article: location.state.story_id,
          children_article: resp.id,
          ordering: ordering,
        };
        API.createItem("chapters", chapter, token)
          .then((resp1) => {
            history.push({
              pathname: "/stories/detail/",
              state: { id: location.state.story_id },
            });
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const updateClicked = () => {
    // if (location.state && isChanged) {
    if (location.state) {
      API.updateItem("articles", location.state.id, state, token)
        .then((resp) => {
          var chapter = {
            ordering: ordering,
            parents_article: selectedParent.value,
          };
          API.updateItem("chapters", chapterid, chapter, token)
            .then((resp1) => {
              history.push({
                pathname: "/stories/detail/",
                state: { id: location.state.story_id },
              });
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  };

  const onParentChange = (newValue, actionMeta) => {
    setSelectedParent(newValue);
    setIsChanged(true);
    console.groupEnd();
  };

  const onCancel = () => {
    history.push({
      pathname: "/stories/detail/",
      state: { id: location.state.story_id },
    });
  };

  useEffect(() => {
    setIsUpdating(false);

    if (location.state) {
      if (location.state.subject) {
        setSelectedSubject(
          commonVariables.SUBJECT_OPTIONS.find((element) => {
            return element.value === location.state.subject;
          })
        );
      }
      if (location.state.category) {
        var link =
          "get_category_by_id/" + location.state.category.toString() + "/";
        API.getItems(link, token)
          .then((resp1) => {
            var link =
              "get_all_parent_articles_by_site_id/" +
              resp1.site.toString() +
              "/";
            API.getItems(link, token)
              .then((resp2) => {
                var parentOptions =
                  dataCommonFunction.createSelectOptionByTitle(resp2.articles);
                setParents(parentOptions);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
      if (location.state.story_id) {
        var link =
          "get_article_by_id/" + location.state.story_id.toString() + "/";
        API.getItems(link, token)
          .then((resp2) => {
            setSelectedParent({
              value: resp2.article.id,
              label: resp2.article.title,
            });
          })
          .catch((error) => console.log(error));
      }
      if (location.state.id) {
        var chapter_id = location.state.id;
        setIsUpdating(true);
        setIsChanged(false);
        API.getItem("articles", chapter_id, token)
          .then((resp) => {
            setState({
              ...state,
              id: resp.id,
              title: resp.title,
              author: resp.author,
              slug: resp.slug,
              category: resp.category,
              tags: resp.tags,
              active: resp.active,
              is_parent: resp.is_parent,
              subject: resp.subject,
              video_url: resp.video_url,
              audio_url: resp.audio_url,
              is_finished: resp.is_finished,
              notes: resp.notes,
            });
            setBody(resp.body);
            setNotes(resp.notes);
            setSelectedSubject(
              commonVariables.SUBJECT_OPTIONS.find((element) => {
                return element.value === resp.subject;
              })
            );

            var link =
              "get_chapter_by_parents_children/" +
              location.state.story_id.toString() +
              "/" +
              resp.id.toString() +
              "/";
            API.getItems(link, token)
              .then((resp3) => {
                // var parentOption = {
                //   value: resp3.article.id,
                //   label: resp3.article.title,
                // };
                // setSelectedParent(parentOption);
                setChapterid(resp3.chapter.id);
                setOrdering(resp3.chapter.ordering);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Create Chapter</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Chapter</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">
                {location.state && location.state.id
                  ? "Update Chapter"
                  : "New Chapter"}
              </h3>
              <div className="card-tools">
                {location.state &&
                location.state.id &&
                !location.state.isCopying ? (
                  <a
                    href="javascript:void(0)"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={updateClicked}
                    // disabled={isDisabled}
                  >
                    Update
                  </a>
                ) : (
                  <a
                    href="javascript:void(0)"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={createClicked}
                    disabled={isDisabled}
                  >
                    Create
                  </a>
                )}
                <a
                  href="javascript:void(0)"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to discard changes?"
                      )
                    )
                      onCancel();
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title</label>
                    {location.state && location.state.id ? (
                      <input
                        id="title"
                        name="title"
                        className="form-control"
                        type="text"
                        placeholder="Enter title"
                        value={state.title}
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        id="title"
                        name="title"
                        className="form-control"
                        type="text"
                        placeholder="Enter title"
                        value={state.title}
                        onChange={handleSlugChange}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Ordering</label>
                    <input
                      id="ordering"
                      name="ordering"
                      className="form-control"
                      type="number"
                      placeholder="Enter ordering"
                      value={ordering}
                      onChange={handleOrderingChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Video URL</label>
                    <input
                      id="video_url"
                      name="video_url"
                      className="form-control"
                      type="text"
                      placeholder="Enter video url"
                      value={state.video_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedSubject}
                      name="subject"
                      options={commonVariables.SUBJECT_OPTIONS}
                      onChange={onSubjectChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Slug</label>
                    <input
                      id="slug"
                      name="slug"
                      className="form-control"
                      type="text"
                      placeholder="Enter slug"
                      value={state.slug}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Parent</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedParent}
                      name="parent"
                      options={parents}
                      onChange={onParentChange}
                      placeholder="Select parent"
                    />
                  </div>
                  <div className="form-group">
                    <label>Audio URL</label>
                    <input
                      id="audio_url"
                      name="audio_url"
                      className="form-control"
                      type="text"
                      placeholder="Enter audio url"
                      value={state.audio_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group clearfix">
                    <label>Is active?</label>
                    <div className="icheck-primary d-inline ustorekit-common-margin-10-side">
                      <input
                        type="checkbox"
                        checked={state.active}
                        id="active"
                        name="active"
                        onChange={handleChange}
                      />
                      <label htmlFor="active"></label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    <label>Content</label>
                    {/* <textarea
                      className="form-control"
                      id="body"
                      name="body"
                      cols="100"
                      rows="6"
                      defaultValue={state.body}
                      placeholder="Enter content"
                      onChange={handleChange}
                    ></textarea> */}
                    <MyEditor
                      handleChange={(data) => {
                        setState({ ...state, body: data });
                        setBody(data);
                      }}
                      token={token}
                      path="article_files"
                      data={body}
                      {...props}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      id="notes"
                      name="notes"
                      cols="100"
                      rows="6"
                      defaultValue={state.notes}
                      placeholder="Enter notes"
                      onChange={handleNotesChange}
                    ></textarea>
                    <p>
                      {count}
                      {count > 1 ? " characters" : " character"}
                    </p>
                    {/* <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={selectedVoice}
                      name="voice"
                      options={commonVariables.VOICE_OPTIONS}
                      onChange={onVoiceChange}
                    />
                    <button
                      className="btn btn-info"
                      onClick={createAudio}
                      // disabled={isDisabled}
                    >
                      Create audio
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {location.state && location.state.id ? (
                <button
                  className="btn btn-info"
                  onClick={updateClicked}
                  // disabled={isDisabled}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-info"
                  onClick={createClicked}
                  // disabled={isDisabled}
                >
                  Create
                </button>
              )}
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
