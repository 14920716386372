export class API {
  // static loginUser(body) {
  //   return fetch(`http://127.0.0.1:8000/auth/`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify( body )
  //   }).then( resp => resp.json())
  // }
  // static registerUser(body) {
  //   return fetch(`http://127.0.0.1:8000/api/users/`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify( body )
  //   }).then( resp => resp.json())
  // }

  // static getJobs(token){
  //   return fetch("http://127.0.0.1:8000/api/movies/", {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Token ${token}`
  //     }
  //   }).then( resp => resp.json())
  // }
  // static getColumns(token){
  //     return fetch("http://127.0.0.1:8000/api/movies/", {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Token ${token}`
  //       }
  //     }).then( resp => resp.json())
  //   }
  // static getDashboardItems(item, token, options = "") {
  //   if (options) {
  //     return fetch(`${process.env.REACT_APP_API_URL}/${item}/?${options}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     }).then((resp) => resp.json());
  //   } else {
  //     return fetch(`${process.env.REACT_APP_API_URL}/${item}/`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     }).then((resp) => resp.json());
  //   }
  // }
  static getItems(item, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }
  
  static getItem(item, id, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}/${id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static updateItem(item, row_id, body, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}/${row_id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static createItem(item, body, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static createItemFront(item, body) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static callAnAction(item, row_id, body, func, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}/${row_id}/${func}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static createItem2 = async (item, body, token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${item}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  };

  static deleteItem(item, row_id, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/${item}/${row_id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
  }
}
