import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import AuthContext from "../common/AuthContext";
import { useHistory, useLocation } from "react-router-dom";

export default function SiteForm() {
  const history = useHistory();
  const location = useLocation();
  const initialState = {
    name: "Gác Sấu Béo",
    email: "saubeo1210@gmail.com",
    body: "",
    article: location.state.article,
    parent: location.state.id,
    active: true,
  };
  const [state, setState] = useState(initialState);

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);
  const isDisabled = state.body.length === 0;

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const createClicked = () => {
    API.createItem("comments", state, token)
      .then((resp) => {
        history.push("/comments/");
      })
      .catch((error) => console.log(error));
  };

  const onCancel = () => {
    // if (location.state) {
    //   if (location.state.jobid) {
    //     history.push({
    //       pathname: "/jobs/detail/",
    //       state: { id: location.state.jobid },
    //     });
    //   } else {
    //     history.push("/sites/");
    //   }
    // } else {
    //   history.push("/sites/");
    // }
    history.push("/comments/");
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Reply comment</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Reply comment</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">Reply comment</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      id="name"
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Enter name"
                      value={state.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      id="email"
                      name="email"
                      className="form-control"
                      type="email"
                      placeholder="Enter email"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Body</label>
                    <textarea
                      id="body"
                      name="body"
                      className="form-control"
                      placeholder="Enter body"
                      value={state.body}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-info"
                onClick={createClicked}
                disabled={isDisabled}
              >
                Create
              </button>
              <button
                className="btn btn-default float-right"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
