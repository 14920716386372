import React, { useReducer } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthContext from "./components/common/AuthContext";
import Login from "./components/accounts/Login";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./components/dashboard/Dashboard";
import NotFound from "./components/common/NotFound";
import Homepage from "./components/homepage/Homepage";
import Articles from "./components/articles/Articles";
import Sites from "./components/articles/Sites";
import SiteForm from "./components/articles/SiteForm";
import Categories from "./components/articles/Categories";
import CategoryForm from "./components/articles/CategoryForm";
import ArticleForm from "./components/articles/ArticleForm";
import CkeUploader from "./components/common/CkeUploader";
import CkeBrowser from "./components/common/CkeBrowser";
import Stories from "./components/stories/Stories";
import ChapterForm from "./components/stories/ChapterForm";
import AddExistChapter from "./components/stories/AddExistChapter";
import StoryDetail from "./components/stories/StoryDetail";
import ArticleDetail from "./components/articles/ArticleDetail";
import Comments from "./components/comments/Comments";
import CommentDetail from "./components/comments/CommentDetail";
import Reply from "./components/comments/Reply";
import CreateAdsIcon from "./components/dashboard/CreateAdsIcon";
import Genres from "./components/articles/Genres";
import GenreForm from "./components/articles/GenreForm";
import Authors from "./components/articles/Authors";
import AuthorForm from "./components/articles/AuthorForm";
import TTSGoogleUnlogin from "./components/homepage/TTSGoogleUnlogin";
import TTSViettelUnlogin from "./components/homepage/TTSViettelUnlogin";
import TTSViettel from "./components/texttospeech/TTSViettel";
import TTSGoogle from "./components/texttospeech/TTSGoogle";

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") || false,
  // user: null,
  token: localStorage.getItem("token") || null,
};
const reducer = (authState, action) => {
  switch (action.type) {
    case "LOGIN":
      // localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      localStorage.setItem("isAuthenticated", true);
      return {
        ...authState,
        isAuthenticated: true,
        // user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...authState,
        isAuthenticated: false,
        //user: null,
      };
    default:
      return authState;
  }
};

export default function App() {
  const [authState, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
      }}
    >
      <Router className="App">
        <Switch>
          {authState.isAuthenticated ? (
            <React.Fragment>
              <MainLayout>
                <Route path="/" component={Dashboard} exact />
                <Route path="/tts_viettel/" component={TTSViettel} exact />
                <Route path="/tts_google/" component={TTSGoogle} exact />
                <Route path="/comments/" component={Comments} exact />
                <Route
                  path="/comments/detail/"
                  component={CommentDetail}
                  exact
                />
                <Route path="/comments/reply/" component={Reply} exact />
                <Route path="/articles/" component={Articles} exact />
                <Route path="/articles/create/" component={ArticleForm} exact />
                <Route
                  path="/articles/detail/"
                  component={ArticleDetail}
                  exact
                />
                <Route path="/sites/" component={Sites} exact />
                <Route path="/sites/create/" component={SiteForm} exact />
                <Route path="/categories/" component={Categories} exact />
                <Route
                  path="/categories/create/"
                  component={CategoryForm}
                  exact
                />
                <Route path="/genres/" component={Genres} exact />
                <Route path="/genres/create/" component={GenreForm} exact />
                <Route path="/authors/" component={Authors} exact />
                <Route path="/authors/create/" component={AuthorForm} exact />
                <Route path="/stories/" component={Stories} exact />
                <Route path="/chapters/create/" component={ChapterForm} exact />
                <Route
                  path="/chapters/add_exist_chapter/"
                  component={AddExistChapter}
                  exact
                />
                <Route path="/stories/detail/" component={StoryDetail} exact />
                <Route
                  path="/create_ads_icon/"
                  component={CreateAdsIcon}
                  exact
                />
                {/* <Route path="/uploader/upload/" component={CkeUploader} /> */}
                {/* <Route
                  path="/uploader/upload?CKEditor=:CKEditor&CKEditorFuncNum=:CKEditorFuncNum&langCode=:langCode"
                  component={CkeUploader}
                /> */}
              </MainLayout>
              <Route path="/browser/browse/" component={CkeBrowser} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route path="/" component={Homepage} exact />
              <Route path="/accounts/login/" component={Login} exact />
              <Route path="/articles" component={Homepage} />
              <Route
                path="/tts_viettel_unlogin/"
                component={TTSViettelUnlogin}
                exact
              />
              <Route
                path="/tts_google_unlogin/"
                component={TTSGoogleUnlogin}
                exact
              />
            </React.Fragment>
          )}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
