import React, { useState, useEffect } from "react";
import queryString from "query-string";
import CkeditorUpload from "./CkeditorUpload";
import axios from "axios";

export default function CkeBrowser(props) {
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  //   const [deleteSavedFiles, setDeleteSavedFiles] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  //   const [isChanged, setIsChanged] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    location: { search },
  } = props;
  const urlParams = queryString.parse(search);

  const getUploadedFiles = (list) => {
    setFiles(list);
    setIsDisabled(false);
  };

  const getDeleteSavedFile = (file) => {
    console.log("getDeleteSavedFile");
    // var files = deleteSavedFiles;
    // files.push(file);
    // setDeleteSavedFiles(files);
    // setIsChanged(true);
  };

  const uploadClicked = () => {
    console.log("Uploading");
    const formData = new FormData();
    // formData.append(item, itemID);
    // formData.append("name", validFiles[i].name);
    // formData.append("size", validFiles[i].size);
    // formData.append("type", validFiles[i].type);
    // formData.append("lastModifiedDate", validFiles[i].lastModifiedDate);
    // formData.append("file", validFiles[i]);

    // axios
    //   .post(`${process.env.REACT_APP_API_URL}/${path}/`, formData, {
    //     headers: {
    //       Authorization: `Token ${token}`,
    //     },
    //     onUploadProgress,
    //   })
    //   .then((response) => list.push(response.data.id))
    //   .catch((e) => {
    //     console.log(`😱 Axios request failed: ${e.response.data}`);
    //   });
    window.opener.CKEDITOR.tools.callFunction(urlParams.CKEditorFuncNum, fileUrl);
    window.close();
  };

  useEffect(() => {
    console.log("CKEditor", urlParams.CKEditor);
    console.log("CKEditorFuncNum", urlParams.CKEditorFuncNum);
    console.log("langCode", urlParams.langCode);
  }, []);
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <CkeditorUpload
                    getUploadedFiles={getUploadedFiles}
                    urlParams={urlParams}
                  />
                </div>
              </div>
            </div>
            {/* <div>
              <button
                className="btn btn-info"
                onClick={uploadClicked}
                disabled={isDisabled}
              >
                Upload
              </button>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  );
}
