import React, { useState, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import AuthContext from "../common/AuthContext";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

export default function Login() {
  let history = useHistory();
  const { authState, dispatch } = useContext(AuthContext);

  const initialState = {
    username: "",
    password: "",
    password2: "",
    email: "",
    agreeTerms: false,
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = useState(initialState);

  const [isLoginView, setIsLoginView] = useState(true);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });

    if (isLoginView) {
      console.log(
        "${process.env.REACT_APP_API_URL}",
        `${process.env.REACT_APP_API_URL}/accounts/login/`
      );
      fetch(`${process.env.REACT_APP_API_URL}/accounts/login/`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: data.username,
          password: data.password,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((resJson) => {
          setData({
            ...data,
            isSubmitting: false,
          });
          dispatch({
            type: "LOGIN",
            payload: resJson,
          });
          // window.location.href = "/hr/dashboard/";
          history.push("/");
        })
        .catch((error) => {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: error.message || error.statusText,
          });
        });
    } else {
      if (data.agreeTerms) {
        if (data.password !== data.password2) {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: "Passwords don't match",
          });
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/accounts/users/`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: data.username,
              email: data.email,
              password: data.password,
              groups: 3,
              first_name: "",
              last_name: "",
            }),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
            })
            .then((resJson) => {
              setIsLoginView(true);
              setData({
                ...data,
                isSubmitting: false,
                errorMessage: "",
              });
            })
            .catch((error) => {
              console.log(error.response);
              setData({
                ...data,
                isSubmitting: false,
                errorMessage: error.message || error.statusText,
              });
            });
        }
      } else {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: "Please agree with our Terms",
        });
      }
    }
  };

  const toggleView = () => {
    setIsLoginView(!isLoginView);
  };

  const updateCheckBox = () => {
    setData({
      ...data,
      agreeTerms: !data.agreeTerms,
    });
  };

  return (
    <React.Fragment>
      {!authState.isAuthenticated ? (
        <div className="login-page">
          <div className="login-box">
            <div className="login-logo">
              <a href="../../index2.html">
                <b>Admin</b>LTE
              </a>
            </div>
            {/* /.login-logo */}

            <div className="card">
              <div className="card-body login-card-body">
                {isLoginView ? (
                  <div>
                    <LoginForm
                      data={data}
                      handleInputChange={handleInputChange}
                      handleFormSubmit={handleFormSubmit}
                    />
                    <p className="mb-0">
                      <a href="#" onClick={toggleView} className="text-center">
                        Register a new membership
                      </a>
                    </p>
                  </div>
                ) : (
                  <div>
                    <RegisterForm
                      data={data}
                      handleInputChange={handleInputChange}
                      handleFormSubmit={handleFormSubmit}
                      updateCheckBox={updateCheckBox}
                    />
                    <a href="#" onClick={toggleView} className="text-center">
                      I already have a membership
                    </a>
                  </div>
                )}
              </div>
              {/* /.login-card-body */}
            </div>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}
