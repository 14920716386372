import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicExtended from "ckeditor5-build-classic-extended";
// import AddAds from "./AddAds";

// const API_URL = "https://77em4-8080.sse.codesandbox.io";
// const UPLOAD_ENDPOINT = "upload_files";

export default function MyEditor({ handleChange, token, path, ...props }) {
  function uploadAdapter(loader) {
    console.log("MyEditor.uploadAdapter");
    return {    
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            // formData.append(item, itemID);
            formData.append("name", file.name);
            formData.append("size", file.size);
            formData.append("type", file.type);
            formData.append("lastModifiedDate", file.lastModifiedDate);
            formData.append("file", file);
            
            // fetch(`${process.env.REACT_APP_API_URL}/${path}/`, {
            fetch(`${process.env.REACT_APP_API_URL}/ckefiles/`, {
              headers: {
                Authorization: `Token ${token}`,
              },
              method: "post",
              body: formData,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.file,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className='myeditor'>
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
        }}
        editor={ClassicEditor}
        onReady={(editor) => {}}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
        {...props}
      />
    </div>
  );
}
