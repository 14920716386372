export class commonVariables {
  static SUBJECT_OPTIONS = [
    { value: "0", label: "Normal" },
    { value: "1", label: "Story" },
    { value: "2", label: "Audio" },
  ];
  static GENDER_OPTIONS = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];
  static VOICE_OPTIONS = [
    { value: "0", label: "vi-VN-Neural2-A" },
    { value: "1", label: "vi-VN-Standard-A" },
    { value: "2", label: "vi-VN-Standard-C" },
  ];

  static getGenderLabel = (gender) => {
    if (gender === "M") {
      return "Male";
    } else {
      return "Female";
    }
  };
}
