import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import ReactHtmlParser from "react-html-parser";
import AuthContext from "../common/AuthContext";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function CommentDetail() {
  const location = useLocation();
  const initialState = {
    article: "",
    name: "",
    email: "",
    body: "",
    created: "",
    active: "",
    parent: "",
  };
  const [state, setState] = useState(initialState);
  const [article_title, setArticleTitle] = useState("");
  const [parent, setParent] = useState("");

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        var comment_id = location.state.id;
        API.getItem("comments", comment_id, token)
          .then((resp) => {
            setState({
              ...state,
              article: resp.article,
              name: resp.name,
              email: resp.email,
              body: resp.body,
              created: resp.created,
              active: resp.active,
              parent: resp.parent,
            });
            console.log("resp.article", resp.article);
            console.log("resp.parent", resp.parent);
            var link =
              "get_article_title_by_id/" + resp.article.toString() + "/";
            console.log("link", link);
            API.getItems(link, token)
              .then((resp2) => {
                setArticleTitle(resp2.article.title);
              })
              .catch((error) => console.log(error));

            API.getItem("comments", resp.parent, token)
              .then((resp3) => {
                setParent(resp3);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Comment Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Comment Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <Link
                to={{
                  pathname: `/comments/reply/`,
                  state: { id: location.state.id, article: state.article },
                }}
                className="cell-button btn btn-success"
              >
                Reply
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h6>
                    <b>Name</b>
                  </h6>
                  <p>{state.name}</p>
                </div>
                <div className="col-md-6">
                  <h6>
                    <b>Email</b>
                  </h6>
                  <p>{state.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h6>
                    <b>Created at</b>
                  </h6>
                  <p>{state.created}</p>
                </div>
                <div className="col-md-6">
                  <h6>
                    <b>Active</b>
                  </h6>
                  <p>{state.active ? "Yes" : "No"}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h6>
                    <b>Article</b>
                  </h6>
                  <p>{article_title}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h6>
                    <b>Parent</b>
                  </h6>
                  <p>{state.parent == "" ? "No" : parent.body}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h6>
                    <b>Body</b>
                  </h6>
                  <div className="form-group clearfix ck-content">
                    {state.body ? ReactHtmlParser(state.body) : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
