import React from "react";
import { Link } from "react-router-dom";

export default function Homepage() {
  return (
    <React.Fragment>
      <Link to={"/accounts/login/"}>Login</Link><br />
      <Link to={"/tts_viettel_unlogin/"}>Text to speech - Viettel</Link><br />
      <Link to={"/tts_google_unlogin/"}>Text to speech - Google</Link><br />
    </React.Fragment>
  );
}
