// import React, { useContext } from "react";
import React from "react";
// import AuthContext from "../common/AuthContext";
import Header from "./Header";
import Menu from "./Menu";
// import Footer from "./Footer";

export default function MainLayout(props) {
  // const { state } = useContext(AuthContext);

  return (
    <React.Fragment>
      {/* {state.isAuthenticated ? ( */}
      <div>
        <Header />
        <Menu />
        {props.children}
        {/* <Footer /> */}
      </div>
      {/* ) : ( */}
      {/* <Redirect to="/accounts/login" /> */}
      {/* )} */}
    </React.Fragment>
  );
}
