import React, { useState, useEffect, useContext } from "react";
import { API } from "../common/api-service";
import ReactHtmlParser from "react-html-parser";
import AuthContext from "../common/AuthContext";
import { useLocation } from "react-router-dom";

export default function ArticleDetail(props) {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [notes, setNotes] = useState("");

  const { authState } = useContext(AuthContext);
  const token = authState.token.slice(1, -1);

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        var article_id = location.state.id;
        API.getItem("articles", article_id, token)
          .then((resp) => {
            setTitle(resp.title);
            setBody(resp.body);
            setNotes(resp.notes);
          })
          .catch((error) => console.log(error));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Article Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Article Detail</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div className="card-header">
              <h3 className="card-title">{title}</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    {body ? ReactHtmlParser(body) : ""}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group clearfix ck-content">
                    {body ? ReactHtmlParser(notes) : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
